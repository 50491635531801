<template>
    <div class="authenBox">
        <div class="statusY" v-show="status == 'Y'">你的认证信息 <span>已通过</span>。若要修改信息，<span>请联系平台客服</span></div>
        <div class="authenContent">

            <div class="stepss">
                <el-steps :active="active" finish-status="success" space:364px>
                    <el-step title="企业资料"></el-step>
                    <el-step title="授权资料"></el-step>
                    <el-step title="完成"></el-step>
                </el-steps>
            </div>

            <div>
                <div v-show="active == 0">
                    <div class="authenTips">
                        <span>温馨提示：认证需要</span>
                        <span>《企业授权委托书》</span>
                        <span>点击</span>
                        <span @click="downloadTheTemplate">下载</span>
                        <span>，香港企业或者境外企业，认证请联系客服400-875-2228</span>
                    </div>

                    <div class="authenAdd">
                        <!-- <el-upload class="upload-demo" action="https://testh.ceecexpo.com/pcApi/file/upload"
                            :on-preview="handlePreview" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                            :limit="1" :on-exceed="handleExceed">
                            <img src="../../assets/authentication/jia_plus-cross.png" class="imgs" alt="" />
                            <div class="authenTitle">公司营业执照</div>
                        </el-upload> -->

                        <el-upload class="upload-demo" :action="baseUrl + '/file/upload'" :headers="headerObj"
                            :on-preview="handlePreview" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                            :limit="1" :on-exceed="handleExceed">
                            <img src="../../assets/authentication/jia_plus-cross.png" class="imgs" alt="" />
                            <div class="authenTitle">公司营业执照</div>
                        </el-upload>

                        <div class="authenAddImg" v-show="status == 'Y'">
                            <img :src="authenviewUrl" alt="">
                        </div>
                    </div>

                    <div class="authenInput">
                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/zu 387.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="enterpriseName" placeholder="企业名称"
                                    :disabled="status == 'Y'">
                            </div>
                        </div>
                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/zu 388.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="creditCode" placeholder="信用代码" :disabled="status == 'Y'">
                            </div>
                        </div>
                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/zu 389.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="enterpriseLegalPerson" placeholder="企业法人"
                                    :disabled="status == 'Y'">
                            </div>
                        </div>
                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/zu 390.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="companyAddress" placeholder="公司地址"
                                    :disabled="status == 'Y'">
                            </div>
                        </div>
                    </div>
                    <div class="preservation">
                        <!-- <div class="preservationBtn1" @click="handlepreviousStep" v-show="active == 1">上一步</div> -->
                        <div class="preservationBtn" v-if="status == 'Y'" @click="handlepreserOk">好的</div>
                        <div class="preservationBtn" v-else @click="handlepreservation">保存</div>
                    </div>
                </div>
                <div class="authenInput" v-show="active == 1">
                    <div class="authenTips">
                        <span>温馨提示：认证需要</span>
                        <span>《企业授权委托书》</span>
                        <span>点击</span>
                        <span @click="downloadTheTemplate">下载</span>
                        <span>，香港企业或者境外企业，认证请联系客服400-875-2228</span>
                    </div>

                    <div class="authenAdd">
                        <el-upload class="upload-demo" :action="baseUrl + '/file/upload'" :headers="headerObj"
                            :on-preview="handlePreview" :on-success="handleAvatarSuccess1" :on-remove="handleRemove"
                            :limit="1" :on-exceed="handleExceed">
                            <img src="../../assets/authentication/jia_plus-cross.png" class="imgs" alt="" />
                            <div class="authenTitle">企业授权委托书</div>
                        </el-upload>
                    </div>
                    <div class="authenInput">
                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/zu 389.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="contacts" placeholder="联系人">
                            </div>
                        </div>

                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/tel.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="contactNumber" placeholder="联系电话">
                            </div>
                        </div>

                        <div class="iputItem">
                            <div class="img">
                                <img src="../../assets/authentication/emil.png" alt="">
                            </div>
                            <div class="input">
                                <input type="text" v-model="companyEmail" placeholder="公司邮箱">
                            </div>
                        </div>
                    </div>
                    <div class="preservation">
                        <div class="preservationBtn1" @click="handlepreviousStep" v-show="active == 1">上一步</div>
                        <div class="preservationBtn2" @click="handleXiaYiBu">保存</div>
                    </div>

                </div>
                <div class="authenInput" v-show="active == 2">

                    <div class="completeS">你已经完成认证，请耐心等待审核，去逛逛！</div>
                    <div class="preservationBtn3" @click="handlepreservation3">保存</div>
                </div>
            </div>



        </div>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import axios from 'axios';
import Footer_Compontent from '../../components/Footer_Compontent.vue';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "Authen",
    components: { Footer_Compontent },
    data() {
        return {
            baseUrl,
            enterpriseName: "", //企业名称
            creditCode: "", //信用代码
            enterpriseLegalPerson: "",//企业法人
            companyAddress: "",//公司地址
            contacts: "", //联系人
            contactNumber: "",//联系电话
            companyEmail: "", //公司邮箱
            active: 0,
            tabs: 1,
            files: false,
            // 上传的图片
            imageUrl: '',
            businessLicense: "", //营业执照URL
            powerOfAttorney: "",//企业委托书URL

            authenview: '', //认证详情
            status: "",
            authenviewUrl: "",

            headerObj: {
                'authorization': localStorage.getItem("token")
            }
        }
    },
    created() {
        var token = sessionStorage.getItem("token");
        axios.get(`${baseUrl}/authen/view`, {  //认证详情
            // params: {},
            headers: { 'authorization': token }
        })
            .then(res => {
                if (res.data.success == true) {
                    this.authenview = res.data.result;
                    this.status = res.data.result.status;


                    this.enterpriseName = this.authenview.enterpriseName;
                    this.creditCode = this.authenview.creditCode;
                    this.enterpriseLegalPerson = this.authenview.legalPerson;
                    this.companyAddress = this.authenview.address;
                    this.authenviewUrl = this.authenview.license
                }

                console.log(this.authenview);
            })
            .catch(err => {
                console.error(err);
            })
    },

    methods: {
        handlepreviousStep() {  //上一步
            this.active--
        },
        handlepreservation() { // 第一步 保存
            var token = sessionStorage.getItem("token")
            if (this.businessLicense == '') {
                this.$message({
                    showClose: true,
                    message: '请上传营业执照',
                    type: 'warning'
                });
                return false
            } else if (this.enterpriseName == '') {
                this.$message({
                    showClose: true,
                    message: '请输入企业名称',
                    type: 'warning'
                }); return false
            } else if (this.creditCode == '') {
                this.$message({
                    showClose: true,
                    message: '请输入信用代码',
                    type: 'warning'
                }); return false
            } else if (this.enterpriseLegalPerson == '') {
                this.$message({
                    showClose: true,
                    message: '请输入企业法人',
                    type: 'warning'
                }); return false
            } else if (this.companyAddress == '') {
                this.$message({
                    showClose: true,
                    message: '请输入公司地址',
                    type: 'warning'
                }); return false
            } else {
                this.active++;
            }

        },
        handleXiaYiBu() {  //第二布 保存
            console.log(this.businessLicense);
            console.log(this.powerOfAttorney);
            if (this.powerOfAttorney == '') {
                this.$message({
                    showClose: true,
                    message: '企业授权委托书',
                    type: 'warning'
                });
                return false
            } else if (this.contacts == '') {
                this.$message({
                    showClose: true,
                    message: '请输入联系人',
                    type: 'warning'
                });
                return false
            } else if (this.contactNumber == '') {
                this.$message({
                    showClose: true,
                    message: '请输入联系电话',
                    type: 'warning'
                });
                return false
            } else if (this.companyEmail == '') {
                this.$message({
                    showClose: true,
                    message: '请输入公司邮箱',
                    type: 'warning'
                });
                return false
            } else {
                var token = sessionStorage.getItem("token")
                axios.post(`${baseUrl}/authen/add`, {
                    enterpriseName: this.enterpriseName,//企业名称
                    creditCode: this.creditCode,//信用代码
                    legalPerson: this.enterpriseLegalPerson,//企业法人
                    address: this.companyAddress,//公司地址
                    license: this.businessLicense, //营业执照
                    contacts: this.contacts,//联系人
                    tel: this.contactNumber,//联系电话
                    email: this.companyEmail,//公司邮箱
                    entrust: this.powerOfAttorney, //委托书
                }, {
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        console.log(res.data)
                        if (res.data.success == true) {
                            this.active++
                        } else if (res.data.success == false) {
                            let message = res.data.message
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'warning'
                            });
                            this.$router.push("/PersonalCenter/MyHomePage")
                            return false
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
        handlepreservation3() {
            this.$router.push("/PersonalCenter/MyHomePage")
        },

        handlepreserOk() {
            this.$router.push("/PersonalCenter/MyHomePage")
        },
        // change(e) {
        //     console.log(e.target.files[0].name);
        //     // 判断是不是规定格式
        //     // let name  =  e.target.files[0].name

        //     // 获取到第一张图片
        //     let file = e.target.files[0]

        //     // 创建文件读取对象
        //     var reader = new FileReader()
        //     var that = this

        //     //  将文件读取为DataURL
        //     reader.readAsDataURL(file)

        //     // 读取成功调用方法
        //     reader.onload = e => {
        //         console.log('读取成功');
        //         this.files = true;
        //         // e.target.result 获取 读取成功后的  文件DataURL
        //         that.imageUrl = e.target.result;
        //         console.log(that.imageUrl);

        //     }

        // },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log("==", file);
            this.businessLicense = file.response.result.fileName; //营业执照

            // axios.post(`${baseUrl}/file/upload`, {
            //     file: ""
            // })
            //     .then(res => {
            //         console.log(res)
            //     })
            //     .catch(err => {
            //         console.error(err);
            //     })
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(file.response.result.fileName);
            this.powerOfAttorney = file.response.result.fileName; //企业委托书
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file.name);
        },
        handleExceed(files, fileList) {
            console.log(fileList);
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
            /* ，共选择了 ${files.length + fileList.length} 个文件 */
        },

        downloadTheTemplate() {
            // this.download('', false),
            let dom = document.createElement("a");
            dom.href = "https://tu.ceecexpo.com/upload/pc/auth.docx";
            dom.download = "https://tu.ceecexpo.com/upload/pc/auth.docx";
            document.body.appendChild(dom);
            // 点击下载
            dom.click();
            document.body.removeChild(dom);
        },

    },
}
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 284px;
}

::v-deep .is-process {
    color: #333333 !important;
}

::v-deep .el-step__title {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
}

::v-deep .el-step__icon {
    width: 32px;
    height: 32px;
    background: #999999;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    border: 0;
}

::v-deep .is-success {
    .el-step__icon {

        background: #1890FF;
        color: #FFFFFF;
    }
}

::v-deep .el-step__head.is-process>.el-step__icon {
    background: #1890FF;
    color: #fff;
}

::v-deep .is-process {
    .el-step__icon {
        color: #FFFFFF;
    }
}

::v-deep .el-step.is-horizontal .el-step__line {
    top: 15px;

    .el-step__line-inner {
        border-color: #999999;
    }
}


.authenBox {
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.1);
    overflow: hidden;

    .statusY {
        width: 1200px;
        margin: 12px auto 0;

        // line-height: 24px;
        span:nth-child(1) {
            color: #76c179;
        }

        span:nth-child(2) {
            color: #111111;
            font-weight: bold;
        }
    }

    .authenContent {
        width: 1200px;
        // height: 595px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.3);
        border-radius: 8px 8px 8px 8px;
        margin: 42px auto 0;
        padding: 32px 167px 23px;

        .stepss {}

        .authenTips {
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            margin-top: 30px;

            span:nth-child(2) {
                color: #1890FF;
                cursor: pointer;
            }

            span:nth-child(4) {
                color: #1890FF;
                cursor: pointer;
            }
        }

        .authenAdd {
            position: relative;
            width: 760px;
            height: 130px;
            background: rgba(153, 153, 153, 0.3);
            border-radius: 8px 8px 8px 8px;
            margin: 47px auto 0;
            overflow: hidden;

            .authenAddImg {
                position: absolute;
                top: 0;
                left: 50%;
                width: 300px;
                height: 100%;
                transform: translateX(-50%);

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            ::v-deep .el-upload {
                // width: 96px;
                margin-left: 50%;
                margin-top: 24px;
                transform: translateX(-50%);
            }

            .file {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .adds {
                width: 32px;
                height: 32px;
                // background: #999999;
                margin: 24px auto 0;

                // input[type="file"] {
                //     display: none;
                // }

            }

            .avatar-uploader {
                // margin-left: 50%;
            }

            .avatar {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .authenTitle {
                margin: 12px auto 0;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }
        }

        .authenInput {
            width: 760px;
            margin: 24px auto 0;

            .iputItem {
                display: flex;
                margin-top: 32px;

                .img {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .input {
                    flex: 1;
                    background-color: red;
                    margin-left: 8px;
                    border-bottom: 1px solid #707070;

                    input {
                        width: 100%;
                        height: 100%;
                        border: 0;
                        outline: none;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        background-color: #ffffff;
                    }
                }
            }

            .iputItem:nth-child(1) {
                margin-top: 0;
            }
        }

        .preservationBtn3 {
            width: 160px;
            height: 32px;
            background: #1890FF;
            text-align: center;
            line-height: 32px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            margin: 54px auto 0;
            cursor: pointer;
        }

        .preservationBtn3:hover {

            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
        }

        .completeS {
            margin-top: 200px;
            font-size: 20px;
            font-weight: 400;
            color: #4CAF50;
            text-align: center;
        }

        .preservation {
            display: flex;
            justify-content: space-between;
            margin: 54px auto 0;
            width: 375px;

            .preservationBtn {
                width: 160px;
                height: 32px;
                background: #1890FF;
                text-align: center;
                line-height: 32px;
                margin: 0 auto;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
            }

            .preservationBtn:hover {
                box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.3);
            }

            .preservationBtn1 {
                width: 160px;
                height: 32px;
                background: #F6F6F6;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }

            .preservationBtn1:hover {
                box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.3);
            }

            .preservationBtn2 {
                width: 160px;
                height: 32px;
                background: #1890FF;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
            }

            .preservationBtn2:hover {
                box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            }
        }
    }
}
</style>